import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
var LAYER_TOGGLE_EVENT_NAME = 'mpr-toggle-3d-layer';

/**
 * This hook is used to manage 3D models layer visibility
 * @returns {Object}
 */
var use3DLayer = function use3DLayer() {
  var _useSelector = useSelector(function (state) {
      return state.app.project.configs;
    }),
    initiallyShowThreeD = _useSelector.initiallyShowThreeD;
  var _useState = useState(initiallyShowThreeD === false ? false : true),
    visible = _useState[0],
    setVisible = _useState[1];
  var toggleVisibility = useCallback(function () {
    var event = new CustomEvent(LAYER_TOGGLE_EVENT_NAME, {
      detail: {
        visible: !visible
      }
    });
    document.dispatchEvent(event);
    setVisible(!visible);
  }, [visible]);
  var setVisibility = useCallback(function (value) {
    var event = new CustomEvent(LAYER_TOGGLE_EVENT_NAME, {
      detail: {
        visible: value
      }
    });
    document.dispatchEvent(event);
    setVisible(value);
  }, [visible]);
  return {
    visible: visible,
    toggleVisibility: toggleVisibility,
    setVisibility: setVisibility
  };
};
export default use3DLayer;