import React, {useState} from "react";
import SearchAndFiltersLoader from "../../../components/loaders/SearchAndFiltersLoader/SearchAndFiltersLoader";
import Search from "../../DesktopLayout/SearchAndFilters/Search";
import Filters from "../../DesktopLayout/SearchAndFilters/Filters/Filters";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import {ResultInfo} from '@mappr/react-lib';

function SearchPage({loading, active}) {
    const [filtersExpanded, setFiltersExpanded] = useState(true);

    if (loading) {
        return (
            <div
                className={`container-fluid h-100 w-100 sidebar px-0 position-absolute ${
                    !active ? "page-hidden" : "page-active"
                }`}
            >
                <SearchAndFiltersLoader/>
            </div>
        );
    }

    return (
        <div
            className={`container-fluid h-100 w-100 sidebar px-0 position-absolute ${
                !active ? "page-hidden" : "page-active"
            }`}
        >
            <div className="row h-100 px-3">
                <div className="col-12 align-self-start pt-3">
                    <Search suggestionsLimit={5}
                            isInSubheader={false}
                            onFiltersExpandToggle={setFiltersExpanded}
                            geocoding={false}
                    />
                    <Filters expandable={true}
                             expanded={filtersExpanded}
                             onExpandToggle={setFiltersExpanded}
                    />
                </div>
                <div className="col-12 align-self-end pb-3">
                    <ResultInfo>
                        {({ count }) => (
                            <Link to={{ pathname: 'list', hash: window.location.hash }}
                                  className="btn btn-primary btn-lg btn-block text-normal">
                                <Trans i18nKey="search.results.number" count={count}>
                                    <span className="search-result-text font-weight-normal">See</span> <span
                                    className="search-result-count">{{ count }}</span> <span className="search-result-text">results</span>
                                </Trans>
                            </Link>
                        )}
                    </ResultInfo>
                </div>
            </div>
        </div>
    );
}

export default SearchPage;
