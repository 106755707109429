import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import POIFields from "../../POI/POIFields/POIFields";
import POIField from "../../POI/POIFields/POIField/POIField";
import ReactMarkdown from "react-markdown";
import ReactHtmlParser from "react-html-parser";
import ExpandCollapse from "../../ExpandCollapse";
import { Table } from "react-bootstrap";


function SecondaryLayerPopUp(props) {
  const { t } = useTranslation();

  const ICON_TYPES = [
    "dwg",
    "e57",
    "ifc",
    "las",
    "laz",
    "msa",
    "others",
    "pdf",
    "rvt",
    "xlsx",
];

  const {
    properties,
    locale,
    layerID,
    layersGroup,
    fields,
    projectConfigs
  } = props;

  const renderContentItem = useCallback(
    (item, key) => {
        let parentClass = "",
            content = null;
        switch (item.type) {
            case "fields": {
                parentClass = "card-text mb-3";
                content = (
                    <POIFields
                        item={properties}
                        poiFields={projectConfigs.poiPageFields}
                        projectConfigs={projectConfigs}
                        index={item.index}
                        renderItem={(item) => (
                            <POIField
                                {...item}
                                inline={projectConfigs.poiFieldsView === "line"}
                            />
                        )}
                    />
                );
                break;
            }
            case "text": {
                parentClass = "card-text";

                let textContent = properties[item.field] || "";
                if (item.isMarkdown) {
                    const source = textContent.replace(/(?:\r\n|\r|\n)/g, "\n\n");
                    textContent = <ReactMarkdown>{source || ""}</ReactMarkdown>;
                } else {
                    const source = textContent.replace(/(?:\r\n|\r|\n)/g, "\n\n");
                    textContent = ReactHtmlParser(source);
                }

                if (item.expandable) {
                    content = (
                        <ExpandCollapse
                            expandOnBodyClick={true}
                            previewHeight="140"
                            expandText={
                                <button
                                    className="btn btn-link btn-block border-top-0 text-right p-0 text-smaller p-0">
                                    <i className="icon icon-arrow_down mr-2"/> Voir plus
                                </button>
                            }
                            collapseText={
                                <button className="btn btn-link btn-block border-top-0 text-right p-0 text-smaller">
                                    <i className="icon icon-arrow_up mr-2"/> Voir moins
                                </button>
                            }
                            ellipsis={false}
                        >
                            {textContent}
                        </ExpandCollapse>
                    );
                } else {
                    content = textContent;
                }
                break;
            }
            case "table": {
                parentClass = "card-text";
                let tableContents = properties[item.field] || [];
                const newOrder = tableContents.map((a) => {
                    let row = {};
                    item.tableColumns.forEach((c) => (row[c] = a[c]));
                    return row;
                });

                if (tableContents.length > 0) {
                    content = (
                        <Table responsive className="mb-3 mt-3">
                            <tbody>
                            <tr
                                key={"header"}
                                className="text-smaller text-light bg-dark"
                            >
                                {Object.keys(newOrder[0]).map((key) => (
                                    <th className="p-2">
                                        {t(`project:table.column.${key}`)}
                                    </th>
                                ))}
                            </tr>
                            {newOrder.map((item) => (
                                <tr key={item.id} className="text-smaller">
                                    {Object.values(item).map((val) => (
                                        <td className="p-2">{val}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    );
                }
                break;
            }
            case "filesList": {
                parentClass = "card-text";
                let tableContents = properties[item.field] || [];
                const newOrder = tableContents.map((a) => {
                    let row = {};
                    item.tableColumns.forEach((c) => (row[c] = a[c]));
                    return row;
                });
                if (tableContents.length > 0) {
                    content = (
                        <Table responsive className="mb-3 mt-3 files-list">
                            <tbody>
                            <tr
                                key={"file-table-header"}
                                className="text-smaller text-light bg-dark"
                            >
                                {Object.keys(newOrder[0]).map((key) => (
                                    <th className="p-2" key={key}>
                                        {t(`project:table.column.${key}`)}
                                    </th>
                                ))}
                            </tr>
                            {newOrder.map((item, index) => (
                                <tr key={item.id} className="text-smaller ">
                                    {Object.keys(newOrder[0]).map((key) => (
                                        <td className="p-2" key={key}>
                                            {key === "fileName" ?
                                                tableContents[index]["fileUrl"] ? (
                                                    <a
                                                        rel="noreferrer"
                                                        href={tableContents[index]["fileUrl"]}
                                                        target={"_blank"}
                                                    >
                                                        {item[key]}
                                                    </a>
                                                ) : ReactHtmlParser(item[key])
                                                : key === "fileExtension" ? (
                                                    ICON_TYPES.includes(
                                                        item[key].replace(/^\./, "")
                                                    ) ? (
                                                        <img
                                                            src={`/img/files/${item[key].replace(
                                                                /^\./,
                                                                ""
                                                            )}.png`}
                                                            alt=""
                                                            height="28"
                                                        />
                                                    ) : (
                                                        <img
                                                            src="/img/files/others.png"
                                                            alt=""
                                                            height="28"
                                                        />
                                                    )
                                                ) : (
                                                    item[key]
                                                )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    );
                }
                break;
            }
            default: {
                content = item.field;
            }
        }

        return (
            <div key={key} className={[parentClass, item.className].join(" ")}>
                {content}
            </div>
        );
    },
    [properties]
  );


  return (
    <div className='MapPopup bg-white pt-1 rounded'>
      <ul className='list-group list-group-flush w-100 h-100'>
        <li className='list-group-item media list-group-item-action d-block border-0 pl-3 pr-2 overflow-hidden'>
          <div className='media-body overflow-hidden d-inline-block'>
            {!!layersGroup?.customPopUp ? 
              <>
                {layersGroup?.popUpTitleField && 
                  <h5
                    className={'mt-0 mb-2'}
                  >
                    {properties[layersGroup?.popUpTitleField]}
                  </h5>
                }
                {layersGroup?.popUpFields.map((item, index) =>
                  renderContentItem(item, index)
                )}
              </> : <>
                {Array.isArray(fields) ? 
                  fields.map(f => <h5>{properties[f.includes('properties') ? f.split('.')[1] : f]}</h5>) :
                  properties[fields.includes('properties') ? fields.split('.')[1] : fields]}
              </> 
            }

          </div>
        </li>
      </ul>
    </div>
  );
}

SecondaryLayerPopUp.propTypes = {
  properties: PropTypes.object,
  locale: PropTypes.string
};

export default SecondaryLayerPopUp;
